<template>
  <v-col
    class="info-message ma-3"
  >
    <v-row>
      <v-icon
        color="coolgray"
        class="pr-3"
      >
        error_outline
      </v-icon>

      <span class="font-weight-bold coolgray--text">
        {{ statusMessage }}
      </span>
    </v-row>

    <v-row
      v-if="statusMessage === 'Error Unknown'"
      class="pl-9 pt-3 coolgray--text font-weight-medium"
    >
      <span>
        To report your experience,
        <a href="mailto:iadops@oao.freshdesk.com">contact us</a>.
        Outline the steps to reproduce the issue,
        and attach screenshots when possible.
      </span>
    </v-row>

    <v-row
      v-else
      class="pt-3"
    >
      Select a publisher from the omnisearch, located in the header of every page.
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'ErrorUnknown',
  props: {
    statusMessage: {
      type: String,
      default: 'Error Unknown',
    },
  },
}
</script>
